// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
jQuery(document).foundation();

jQuery(document).ready(function() {
  return jQuery(document).on("click", ".left-off-canvas-toggle, .right-off-canvas-toggle", function(e) {
  	e.preventDefault();
    return jQuery(".left-off-canvas-toggle").toggleClass("active");
  });
});

jQuery(document).on("click", ".js-off-canvas-overlay", function(e) {
  	e.preventDefault();
    jQuery(".left-off-canvas-toggle").removeClass("active");
  });

jQuery('[data-toggle]').on('click', function() {

	jQuery(document).trigger('click.zf.trigger', '[data-toggle]');

});

jQuery('.title-bar').click(function(){ false });


/* Top Strip Scroll */

jQuery(window).scroll(function($) {

               if ( jQuery(this).scrollTop() > 175 ) {

                   jQuery(".top-strip").addClass('bg');

               } else if ( jQuery(this).scrollTop() < 175 ) {

                   jQuery(".top-strip").removeClass('bg');

               }
});

/* Fluidbox */

jQuery(function(){
	jQuery('a.fluidbox').fluidbox();
});


/* Make Embeds Responsive */

jQuery(document).ready(function() {

	jQuery('.single-post iframe').wrap('<div class="responsive-embed" />');

});


jQuery(window).load(function() {
	//jQuery('.banner').slideToggle();
});


// doc ready
jQuery(function() {

	/* Product thumbnails */
	jQuery(document).on('click','.thumbnails .zoom', function(e) {
		e.preventDefault();
    	var photo_fullsize = jQuery(this).attr('href');
    	jQuery('.woocommerce-main-image img').attr('src', photo_fullsize);
    	jQuery('.woocommerce-main-image img').attr('srcset', photo_fullsize);
    	return false;
	});


	/* Gallery */

	jQuery(".gallery").justifiedGallery({
		rowHeight : 200,
		lastRow : 'justify',
		captions : false,
		margins : 2
	});


	/* Carousels */

	jQuery('.product-carousel').slick({
		autoplaySpeed: 5000,
  		slidesToShow: 1,
  		centerMode: true,
  		centerPadding: '50px',
    	infinite: true,
    	arrows: false,
    	dots: false,
    	autoplay: true,
    	initialSlide: 1,
	});


	jQuery('.home-carousel').slick({
		arrows: true,
		dots: true,
		autoplay: false,
		adaptiveHeight: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		cssEase: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
		useTransform: true,
		centerMode: true,
		centerPadding: '270px',
		lazyLoad: 'ondemand',
		responsive: [
			{
		  	breakpoint: 1000,
		  	settings: {
		    	centerPadding: '200px',
		  	}
			},
			{
		  	breakpoint: 768,
		  	settings: {
		    	centerPadding: '150px',
		  	}
			},
			{
		  	breakpoint: 480,
		  	settings: {
		    	//arrows: false,
		    	centerPadding: '30px',
		    	dots: false,
		  	}
			}
		]
	});

	jQuery('.music-posts').slick({
  		slidesToShow: 1,
  		centerMode: true,
  		centerPadding: '0',
    	infinite: true,
    	arrows: false,
    	dots: true,
    	//margin: 15,
    	autoplay: true,
    	autoplaySpeed: 4000
	});

	jQuery('.social-feed').slick({
		arrows: false,
		autoplay: false,
		autoplaySpeed: 6000,
		adaptiveHeight: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
		{
	  	breakpoint: 1000,
	  	settings: {
	    	slidesToShow: 3
	  	}
		},
		{
	  	breakpoint: 768,
	  	settings: {
	    	slidesToShow: 2
	  	}
		},
		{
	  	breakpoint: 480,
	  	settings: {
	    	slidesToShow: 1
	  	}
		}
		]
	});

});

//end doc ready


/* Quantity UI Spinner */

jQuery('.quantity').on('click', function() {
	jQuery( '.woocommerce-cart-form :input[name="update_cart"]' ).prop( 'disabled', false ).attr( 'aria-disabled', false );
});

jQuery('.single-product .quantity').on('click', function() {
	jQuery('.single-product .quantity .qty').trigger("input");
});

jQuery(function() {
	jQuery(".single-product .quantity .qty").prop("readonly", true);
});

/* Facebook Sharer */

function fbShare(url, title, descr, image, winWidth, winHeight) {
    var winTop = (screen.height / 2) - (winHeight / 2);
    var winLeft = (screen.width / 2) - (winWidth / 2);
    window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

function googleShare(url, winWidth, winHeight) {
    window.open("https://plus.google.com/share?url=" + url + "", "", "width=" + winWidth + ", height=" + winHeight + "");
}


/* Hide Logo on mobiles */

jQuery('.cd-slider-nav a').click(function(){
	if (Foundation.MediaQuery.atLeast('medium')) { return; } else {
		jQuery('.site-title .shield').hide();
		jQuery('.site-title .script').show();
	}
});


/* Checkout Custom Field Toggles */

/*

function toggleShowShine() {
    if(jQuery("#buyer_data_haveyouenteredyourcarintoourshowshinearea_tcfn_1595_post_meta").is(':checked'))
	    jQuery("#buyer_data_vehicleregistrationshowshineonly_tcfn_7702_post_meta").slideDown();  // checked
	else
	    jQuery("#buyer_data_vehicleregistrationshowshineonly_tcfn_7702_post_meta").slideUp();
}

jQuery('#buyer_data_haveyouenteredyourcarintoourshowshinearea_tcfn_1595_post_meta').change(function () {
	toggleShowShine();
});

jQuery(function() {
	toggleShowShine();
});


function toggleClubStand() {
    if(jQuery("#buyer_data_areyoupartofaclubstand_tcfn_8871_post_meta").is(':checked'))
	    jQuery("#buyer_data_pleaseletusknowwhichclubstandyoureon_tcfn_6585_post_meta").slideDown();  // checked
	else
	    jQuery("#buyer_data_pleaseletusknowwhichclubstandyoureon_tcfn_6585_post_meta").slideUp();
}

jQuery('#buyer_data_areyoupartofaclubstand_tcfn_8871_post_meta').change(function () {
	toggleClubStand();
});

jQuery(function() {
	toggleClubStand();
});

*/


/* Ticket link in hero */

jQuery('#tickets-link').click(function () {
	window.location.href = "https://www.cumbriavag.co.uk/tickets/";
});


/* Thankyou page tickets */

jQuery('.enter-your-reg-btn').click(function (e) {
	e.preventDefault();
	jQuery(this).siblings('.active').removeClass('active');
	jQuery(this).siblings( ".enter-your-reg" ).toggleClass('active');
	jQuery(this).toggleClass('active');
});

jQuery('.enter-your-club-btn').click(function (e) {
	e.preventDefault();
	jQuery(this).siblings('.active').removeClass('active');
	jQuery(this).siblings( ".enter-your-club" ).toggleClass('active');
	jQuery(this).toggleClass('active');
});

// AJAX Popups


jQuery('.popup').on('click', function (e) {

	e.preventDefault();

    var postdata = jQuery(this).attr('href');

    /*jQuery('#ticket-pdp').load(postdata, function(){
	        wc_stripe_payment_request.init();
	    });*/

	jQuery('#ticket-pdp').attr('src',postdata);

    jQuery('#off-canvas-pdp').foundation('open');

    return false;

 });

jQuery("#off-canvas-pdp").on("closed.zf.offcanvas", function(e) {
	var loading_html = 'https://www.cumbriavag.co.uk/loader.html';
	jQuery('#ticket-pdp').attr('src',loading_html);
});


/* Escape IFRAME once ticket has been added or purchased */

jQuery(function(){
	if ( jQuery('body').hasClass('woocommerce-cart') || jQuery('body').hasClass('woocommerce-order-received') ) {
	    if ( window.location !== window.parent.location ) {
		    // if iframe change parent url
		    var iframe_url = window.location;
			window.top.location.href = iframe_url;
	    } else {
		    // if not iframe hide the loader
		    jQuery('#cumvag-ajax-loader').hide();
	    }
	}
});


/* failsafe to hide ajax loader */

function explode_loader(){
  jQuery('#cumvag-ajax-loader').remove();
}
setTimeout(explode_loader, 6000);


/* Ajax update reg custom field */
jQuery(".ticket .submit-reg").submit(function(e){

   e.preventDefault();
   jQuery(".submit-message").remove();
   var ajaxurl = 'https://'+window.location.host+'/wp-admin/admin-ajax.php';
   //var ajaxurl = 'http://localhost/cumbriavag/wp-admin/admin-ajax.php';
   var form = jQuery(this);
   var reg = jQuery(this).children(".vehicle_registration").val();
   var ticket_id_field = jQuery(this).children(".ticket_id").val();
   jQuery.ajax({
	   url: ajaxurl + "?action=attach_ticket",
	   type: 'POST',
	   data: {'vehicle_registration' : reg, 'ticket_id' : ticket_id_field},
	     success: function(data) {
	     console.log(data);

	     if (data=='Worked!') {
		     //jQuery("#submit-reg button").remove();
		     //jQuery('.enter-your-club-btn').remove();
		     //jQuery('.enter-your-club').remove();
			 //jQuery("#vehicle_registration").prop('disabled', true);

			 // message to customer
			 //jQuery("<span class='submit-message'>Thanks. This ticket has been assigned to your Show &amp; Shine entry.</span>").insertAfter(this);
			 alert("Thanks. This ticket has been assigned to your Show & Shine entry. Your pass will be sent out by post a week or two before the show.");
			 form.closest('.assign-wrapper').html('Assigned To Show & Shine Entry: <div class="reg-field">' + reg + '</div>');

	     } else {
		     // message to customer
		     alert("Sorry, we couldn't match that registration to a Show & Shine entry, please try again. Please also double check that any spacing matches the registration used for your application. If the problem persists, please contact us for support.");
			 //jQuery("<span class='submit-message'>Sorry, we couldn't match that registration to a Show &amp; Shine entry, please try again. Please also double check that any spacing matches the registration used for your application.</span>").insertAfter(form);
	     }

	   },
	     error: function(data) {
	     //console.log(data);
	     //jQuery(data).find('.message').insertAfter("#submit-reg");
	     	//jQuery("<span class='submit-message'>Sorry, we couldn't match that registration to a Show &amp; Shine entry, please try again.</span>").insertAfter(this);
	     	alert("Sorry, we couldn't match that registration to a Show & Shine entry, please try again.");
	   }
	});
});


/* Ajax update club stand custom field */
jQuery(".ticket .submit-club").submit(function(e){

   e.preventDefault();
   jQuery(".submit-message").remove();
   var ajaxurl = 'https://'+window.location.host+'/wp-admin/admin-ajax.php';
   //var ajaxurl = 'https://www.cumbriavag.co.uk/wp-admin/admin-ajax.php';
   var form = jQuery(this);
   var club_stand = jQuery(this).children(".club_stand").val();
   var ticket_id_field = jQuery(this).children(".ticket_id").val();
   jQuery.ajax({
	   url: ajaxurl + "?action=attach_ticket_club",
	   type: 'POST',
	   data: {'club_stand' : club_stand, 'ticket_id' : ticket_id_field},
	     success: function(data) {
	     console.log(data);

	     if (data=='Worked!') {
		     //jQuery("#submit-club button").remove();
		     //jQuery('.enter-your-reg-btn').remove();
		     //jQuery('.enter-your-reg').remove();
			 //jQuery("#club_stand").prop('disabled', true);

			 // message to customer
			 //jQuery("<span class='submit-message'>Thanks. This ticket has been assigned to your Show &amp; Shine entry.</span>").insertAfter(this);
			 alert("Thanks. This ticket has been assigned to your Club Stand.");
			 form.closest('.assign-wrapper').html('Assigned To Club Stand: <div class="reg-field">' + club_stand + '</div>');
	     } else {
		     // message to customer
		     alert("Sorry, we are struggling to assign your ticket to this club. Please contact us or the stand organiser to get this resolved.");
			 //jQuery("<span class='submit-message'>Sorry, we couldn't match that registration to a Show &amp; Shine entry, please try again. Please also double check that any spacing matches the registration used for your application.</span>").insertAfter(form);
	     }

	   },
	     error: function(data) {
	     //console.log(data);
	     //jQuery(data).find('.message').insertAfter("#submit-reg");
	     	//jQuery("<span class='submit-message'>Sorry, we couldn't match that registration to a Show &amp; Shine entry, please try again.</span>").insertAfter(this);
	     	alert("Sorry, we are struggling to assign your ticket to this club. Please contact us or the stand organiser to get this resolved.");
	   }
	});
});



jQuery(function($) {

	// jQuery UI Spinner


    $.widget( "ui.customspinner", $.ui.spinner, {
      widgetEventPrefix: $.ui.spinner.prototype.widgetEventPrefix,
      _buttonHtml: function() { // Remove arrows on the buttons
        return "" +
        "<a class='ui-spinner-button ui-spinner-up ui-corner-tr'>" +
        "</a>" +
        "<a class='ui-spinner-button ui-spinner-down ui-corner-br'>" +
        "</a>";
      }
    });


    jQuery('.quantity .qty').customspinner({
      min: 1,
      max: 99
    }).on('focus', function () {
      jQuery(this).closest('.ui-spinner').addClass('focus');
    }).on('blur', function () {
      jQuery(this).closest('.ui-spinner').removeClass('focus');
    });



});